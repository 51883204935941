import React, {useState, useCallback, ChangeEvent, useEffect} from 'react';

import {Box, Input, Button, Stack} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import {useDebounce} from 'use-debounce';

import {Modal} from '@/atoms/Modal';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {AppointmentsList} from '@/molecules/ScheduledAppointments/Screens/index';
import {ScheduledAppointmentsProps} from '@/molecules/ScheduledAppointments/interfaces';
import {useStyles} from '@/molecules/ScheduledAppointments/styles';
import {useAppointmentsV2, AppointmentV2} from '@/store/appointments/v2';

const ScheduledAppointments = ({
  children,
  isReadAndWrite,
  onConfirm,
  disabled = false,
  banner,
  country,
  ...appointmentsParams
}: ScheduledAppointmentsProps) => {
  const {getTranslationWithValue} = useBaseTranslation(
    'FeedbackBox.APPOINTMENT_SETTING',
  );
  const styles = useStyles();
  const {getScheduledTaskAppointments} = useAppointmentsV2();

  const [selectedAppointment, setSelectedAppointment] = useState<
    AppointmentV2 | undefined
  >(undefined);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>(
    appointmentsParams?.q ?? '',
  );
  const [debouncedString] = useDebounce(searchString, 300);

  /**
   * Reset modal state on close
   */
  useEffect(() => {
    if (!showModal) {
      setSearchString(appointmentsParams?.q ?? '');
    }
  }, [appointmentsParams?.q, showModal]);

  /**
   * Handle modal toggle
   */
  const toggleModal = useCallback((value?: boolean) => {
    setShowModal(prevState => {
      return typeof value !== 'undefined' ? value : !prevState;
    });
  }, []);

  /**
   * Handle appointment selection
   */
  const onSelectAppointment = useCallback((appointment?: AppointmentV2) => {
    setSelectedAppointment(prevState => {
      return prevState?.id === appointment?.id ? undefined : appointment;
    });
  }, []);

  /**
   * API to retrive stores by debounceString
   */
  const {data: Appointments, isLoading: AppointmentsLoading} = useQuery(
    [
      'appointments',
      appointmentsParams?.storeId,
      appointmentsParams?.taskId,
      debouncedString,
    ],
    () =>
      getScheduledTaskAppointments({
        storeId: appointmentsParams?.storeId,
        taskId: appointmentsParams?.taskId,
        banner,
        country,
        q: debouncedString,
        removeTaskAppointment: true,
      }),
    {
      retry: false,
      enabled: showModal,
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    },
  );

  /**
   * Handle input search
   */
  const handleSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(_ => e.target.value);
  }, []);

  /**
   * Handle confirm
   */
  const handleConfirm = useCallback(() => {
    onConfirm && selectedAppointment && onConfirm(selectedAppointment);
    toggleModal(false);
  }, [onConfirm, selectedAppointment, toggleModal]);

  return (
    <>
      <Box
        height="max-content"
        {...(!disabled && {
          onClick: () => toggleModal(),
          sx: styles.clickable,
        })}>
        {children && children}
      </Box>
      {showModal && (
        <Modal
          open={showModal}
          onClose={() => toggleModal(false)}
          maxWidth={60}
          dialogTitle={getTranslationWithValue(0, 'modal.dialogTitle')}
          dialogContent={
            <Stack
              direction="column"
              gap={3.2}
              marginTop={3.2}
              width="100%"
              alignItems="center">
              <Box width="100%">
                <Input
                  sx={styles.inputSearch}
                  type="text"
                  fullWidth
                  spellCheck={false}
                  autoCorrect="off"
                  autoComplete="off"
                  placeholder={getTranslationWithValue(
                    0,
                    'modal.searchPlaceholder',
                  )}
                  value={searchString}
                  onChange={handleSearch}
                />
                <AppointmentsList
                  isReadAndWrite={isReadAndWrite}
                  onSelectAppointment={onSelectAppointment}
                  selectedAppointment={selectedAppointment}
                  isLoading={AppointmentsLoading}
                  appointments={Appointments}
                />
              </Box>
              <Button
                variant="basePrimary"
                disabled={!Boolean(selectedAppointment)}
                onClick={() => handleConfirm()}>
                {getTranslationWithValue(0, 'modal.selectAppointmentCta')}
              </Button>
            </Stack>
          }
        />
      )}
    </>
  );
};

export default React.memo(ScheduledAppointments);

import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {Skeleton} from '@mui/material';
import {useInfiniteQuery, useQuery, useMutation} from '@tanstack/react-query';
import dayjs from 'dayjs';

import {DataTable} from '@/atoms/DataTable';
import {LoadingPage} from '@/atoms/LoadingPage';
import {BaseRouterParams} from '@/definitions/base';
import {useToast} from '@/hooks/toast';
import {FilterDialog} from '@/molecules/FilterDialog';
import BarCodeDialog from '@/organisms/RewardListTable/BookDialog/BarCodeDialog';
import {RewardStatus} from '@/organisms/RewardListTable/enums';
import {useAuthentication} from '@/store/authentication';
import {useBenefits} from '@/store/benefits';
import {RewardBookBody, RewardBookResponse} from '@/store/benefits/interfaces';
import {
  RewardsListSetFilters,
  RewardsListReset,
} from '@/store/loyaltyFilters/actions';
import {RewardsListFilter} from '@/store/loyaltyFilters/rewardsList.reducer';
import {selectorRewardsList} from '@/store/loyaltyFilters/selectors';

import {BookDialog} from './BookDialog';
import {FilterRewardForm} from './FilterRewardForm';
import {useColumnDef} from './columnDef';
import {IRewardsTable} from './interfaces';

const RewardListTable = ({enabledApis, canExport}: IRewardsTable) => {
  const {t} = useTranslation();
  const {isContactCenterAgent, isContactCenterSupervisor} = useAuthentication();
  const {showSuccessToast, showErrorToast} = useToast();
  const {userStoreId} = useAuthentication();

  const rewardsListFilters = useSelector<
    {rewardsListFilters: RewardsListFilter},
    RewardsListFilter
  >(selectorRewardsList);

  const dispatch = useDispatch();

  const {RewardListColumnDef} = useColumnDef();

  const [formData, setFormData] = useState<Partial<RewardsListFilter>>();
  const [formValid, setFormValid] = useState<boolean>(false);

  /** Handle export and filter functions*/
  const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
  const [bookDialog, setBookDialog] = useState<boolean>(false);
  const [barCodeDialog, setBarCodeDialog] = useState<boolean>(false);
  const [bookCode, setBookCode] = useState<string>('');
  const [bookPromocode, setBookPromocode] = useState<string>('');
  const [promotion, setPromotion] = useState<string>('');
  const [exportEnabled, setExportEnabled] = useState<boolean>(false);
  const [putBookingState, setPutBookingState] = useState<RewardBookResponse>();

  const {
    getBenefitList,
    exportRewards,
    putBookBooking,
    postBookBookingBarcode,
    postBenefitsSendEmail,
  } = useBenefits();

  const {cardNumber, banner, country} = useParams<BaseRouterParams>();

  const toggleFilterDialog = useCallback((value?: boolean) => {
    setOpenFilterDialog(prevState => {
      return typeof value !== 'undefined' ? value : !prevState;
    });
  }, []);

  const toggleBookDialog = useCallback((value?: boolean) => {
    setBookDialog(prevState => {
      return typeof value !== 'undefined' ? value : !prevState;
    });
  }, []);

  const toggleBarCodeDialog = useCallback((value?: boolean) => {
    setBarCodeDialog(prevState => {
      return typeof value !== 'undefined' ? value : !prevState;
    });
  }, []);

  const apiPayload = useMemo(() => {
    return {
      ...(rewardsListFilters.benefit !== '' && {
        benefitCode: rewardsListFilters.benefit,
      }),
      ...(rewardsListFilters.status !== '' && {
        status: rewardsListFilters.status,
      }),
      ...(rewardsListFilters.dateFrom && {
        startDate: dayjs(rewardsListFilters.dateFrom).format('YYYY-MM-DD'),
      }),
      ...(rewardsListFilters.dateTo && {
        endDate: dayjs(rewardsListFilters.dateTo).format('YYYY-MM-DD'),
      }),
    };
  }, [
    rewardsListFilters.benefit,
    rewardsListFilters.dateFrom,
    rewardsListFilters.dateTo,
    rewardsListFilters.status,
  ]);

  useQuery(
    ['rewardsExport', cardNumber, banner, country, apiPayload],
    () =>
      exportRewards({
        cardNumberSearch: cardNumber,
        bannerSearch: banner,
        countrySearch: country,
        ...apiPayload,
      }),
    {
      enabled: exportEnabled,
      retry: false,
      onSuccess: data => {
        showSuccessToast(t('CustomerProfile.dataTable.toast.export.success'));
      },
      onError: () => {
        showErrorToast(t('CustomerProfile.dataTable.toast.export.error'));
      },
      onSettled: () => {
        setExportEnabled(false);
      },
    },
  );

  const handleExport = useCallback(() => {
    setExportEnabled(true);
  }, []);

  const {
    data: Rewards,
    fetchNextPage,
    isLoading,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    ['rewardsData', cardNumber, banner, country, apiPayload, userStoreId],
    ({pageParam = 1}) =>
      getBenefitList({
        cardNumberSearch: cardNumber,
        bannerSearch: banner,
        countrySearch: country,
        ...apiPayload,
        page: pageParam,
        limit: 10,
      }),
    {
      getNextPageParam: ({metadataDTO: {page, total_pages}}) =>
        page >= total_pages ? undefined : page + 1,
      enabled: enabledApis,
    },
  );

  // Merge infiniteQuery results in a single list
  const benefitsResponse = useMemo(
    () => Rewards?.pages.flatMap(({data}) => data),
    [Rewards],
  );

  const handleBook = useCallback(
    (book: boolean, code: string, promocodeRetail: string, name: string) => {
      book && toggleBookDialog();
      setPutBookingState(undefined);
      setBookCode(code);
      setBookPromocode(promocodeRetail);
      setPromotion(name);
    },
    [toggleBookDialog],
  );

  const {mutate: PutBookBooking, isLoading: PutBookBookingLoading} =
    useMutation({
      mutationFn: (variables: RewardBookBody) => putBookBooking(variables),
      onSuccess: data => {
        setPutBookingState(data);
        showSuccessToast(t('CustomerProfile.dataTable.toast.booking.success'));
        refetch();
      },
      onError: () => {
        showErrorToast(t('CustomerProfile.dataTable.toast.booking.error'));
      },
    });

  const handleConfirmBook = useCallback(() => {
    PutBookBooking({
      cardNumberSearch: cardNumber,
      countrySearch: country,
      bannerSearch: banner,
      code: bookCode,
      promocodeRetail: bookPromocode,
      widthBarCode: 450,
      heightBarCode: 143,
      confirmOpening: true,
    });
  }, [PutBookBooking, banner, bookCode, bookPromocode, cardNumber, country]);

  const {
    mutate: PostBookBookingBarcode,
    isLoading: PostBookBookingBarcodeLoading,
    data: PostBookBookingBarcodeData,
  } = useMutation({
    mutationFn: (variables: RewardBookBody) =>
      postBookBookingBarcode(variables),
    onError: () => {
      setBarCodeDialog(() => false);
      showErrorToast(t('CustomerProfile.dataTable.toast.booking.expired'));
    },
  });

  const handleBarcodeBook = useCallback(
    (code: string, promocodeRetail: string) => {
      PostBookBookingBarcode({
        cardNumberSearch: cardNumber,
        countrySearch: country,
        bannerSearch: banner,
        code: code,
        promocodeRetail: promocodeRetail,
        widthBarCode: 450,
        heightBarCode: 143,
      });
      setBarCodeDialog(() => true);
    },
    [PostBookBookingBarcode, banner, cardNumber, country],
  );

  const {
    mutate: PostBenefitsSendEmail,
    isLoading: PostBenefitsSendEmailLoading,
  } = useMutation({
    mutationKey: ['postBenefitsSendEmail'],
    mutationFn: (variables: Omit<RewardBookBody, 'promocodeRetail'>) =>
      postBenefitsSendEmail(variables),
    onSuccess: () => {
      showSuccessToast(t('CustomerProfile.dataTable.toast.sendEmail.success'));
      refetch();
    },
    onError: () => {
      showErrorToast(t('CustomerProfile.dataTable.toast.sendEmail.error'));
    },
    networkMode: 'online',
  });

  const handleBenefitsSendEmail = useCallback(
    (code: string) => {
      PostBenefitsSendEmail({
        cardNumberSearch: cardNumber,
        countrySearch: country,
        bannerSearch: banner,
        code: code,
        widthBarCode: 450,
        heightBarCode: 143,
      });
    },
    [PostBenefitsSendEmail, banner, cardNumber, country],
  );

  const rows = useMemo(
    () =>
      benefitsResponse?.map(item => {
        return {
          ...item,
          upc:
            (!!item?.upc && item?.upc?.length) > 0
              ? item?.upc?.join(', ')
              : null,
          status: t(
            `CustomerProfile.tables.rewardList.statuses.${item.status.name}`,
            item.status.name,
          ),
          ...(item.status.name === RewardStatus.BOOKED &&
            item.status.clickBook && {
              statusAction: () =>
                handleBarcodeBook(item.code, item.promocodeRetail),
            }),
          statusColor: item.status.color,
          remainingUsages: !item.maxUsageNumber
            ? item.usageNumber
            : `${item.usageNumber} / ${item.maxUsageNumber}`,
          actions: {
            label:
              (item.resendEmail &&
                t('CustomerProfile.tables.rewardList.actions.sendEmail')) ||
              (item.book &&
                t('CustomerProfile.tables.rewardList.actions.claim')),
            onClick: () =>
              (item.resendEmail && handleBenefitsSendEmail(item.code)) ||
              (item.book &&
                handleBook(
                  item.book,
                  item.code,
                  item.promocodeRetail,
                  item.name,
                )),
          },
        };
      }) || [],
    [
      benefitsResponse,
      t,
      handleBarcodeBook,
      handleBenefitsSendEmail,
      handleBook,
    ],
  );

  const applyFilters = useCallback(() => {
    dispatch(RewardsListSetFilters(formData!));
    toggleFilterDialog(false);
  }, [dispatch, formData, toggleFilterDialog]);

  const handleTableReset = useCallback(() => {
    dispatch(RewardsListReset());
  }, [dispatch]);

  const filterNumber = useMemo(() => {
    let filterCopy = {...rewardsListFilters};
    delete filterCopy.dateTo;

    return Object.values(filterCopy).filter(
      val => val !== '' && typeof val !== 'undefined',
    ).length;
  }, [rewardsListFilters]);

  const canResetFilters = useMemo(() => {
    return Boolean(
      Object.values(rewardsListFilters).find(
        val => val !== '' && typeof val !== 'undefined',
      ),
    );
  }, [rewardsListFilters]);

  return (
    <>
      {PostBenefitsSendEmailLoading && <LoadingPage invisible={false} />}
      {isLoading || !rows ? (
        <Skeleton
          height={400}
          width="100%"
          animation="wave"
          variant="rounded"
        />
      ) : (
        <DataTable
          tableId={'rewardsListTable'}
          columnDef={RewardListColumnDef}
          data={rows ?? []}
          maxHeight="450px"
          handleExport={handleExport}
          handleFilter={() => toggleFilterDialog(true)}
          handleFiltersReset={handleTableReset}
          fetchNextPage={fetchNextPage}
          isLoadingMore={hasNextPage}
          columnVisibility={{
            promocodeEcommerce:
              isContactCenterAgent || isContactCenterSupervisor,
          }}
          canExport={canExport}
          canResetFilters={canResetFilters}
          filterNumber={filterNumber}
        />
      )}
      <FilterDialog
        open={openFilterDialog}
        onClose={() => toggleFilterDialog(false)}
        onApply={applyFilters}
        confirmDisabled={!formValid}
        dialogContent={
          <FilterRewardForm
            filters={rewardsListFilters}
            formCB={formState => setFormData(formState)}
            validCB={isValid => setFormValid(isValid)}
          />
        }
      />
      {bookDialog && (
        <BookDialog
          barCode={putBookingState}
          open={bookDialog}
          promotion={promotion}
          onClose={() => {
            toggleBookDialog(false);
            setPutBookingState(undefined);
          }}
          onConfirm={() => handleConfirmBook()}
          isLoading={PutBookBookingLoading}
        />
      )}
      {barCodeDialog && (
        <BarCodeDialog
          open={barCodeDialog}
          barCode={PostBookBookingBarcodeData}
          isLoading={PostBookBookingBarcodeLoading}
          onClose={() => toggleBarCodeDialog(false)}
        />
      )}
    </>
  );
};

export default React.memo(RewardListTable);

import React, {useMemo, useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Slider,
  Stack,
  SxProps,
  Theme,
  Typography,
  Container,
  Input,
} from '@mui/material';
import dayjs from 'dayjs';
import Utc from 'dayjs/plugin/utc';

import {Badge} from '@/atoms/Badge';
import {ContextMenu} from '@/atoms/ContextMenu';
import {Dialog} from '@/atoms/Dialog';
import {CommonButtons} from '@/atoms/DialogButtons';
import {FiltersChips} from '@/atoms/FiltersChips';
import {IconSVG} from '@/atoms/IconSVG';
import {IconNames} from '@/atoms/IconSVG/interfaces';
import {Link} from '@/atoms/Link';
import LuxCard from '@/atoms/LuxCard';
import {PanelToggle} from '@/atoms/PanelToggle';
import {PurchaseCategory} from '@/atoms/PurchaseCategory';
import {PURCHASE_CATEGORIES} from '@/atoms/PurchaseCategory/interfaces.d';
import {Select} from '@/atoms/Select';
import {SelectOptions, SelectValueType} from '@/atoms/Select/interfaces';
import {StarRating} from '@/atoms/StarRating';
import {Tabs} from '@/atoms/Tabs';
import {Tag} from '@/atoms/Tag';
import {TooltipInfo} from '@/atoms/TooltipInfo';
import {Text} from '@/atoms/Typography/Text';
import {UserAvatar} from '@/atoms/UserAvatar';
import {randomLoggedUser, randomLoggedUsers} from '@/atoms/UserAvatar/mock';
import {ValueCircle} from '@/atoms/ValueCircle';
import {useToast} from '@/hooks/toast';
import {CampaignCard} from '@/molecules/CampaignCard';
import {DatePicker} from '@/molecules/DatePicker';
import {HomepageFormbar} from '@/molecules/HomepageFormbar';
import {HomepageFormBarProps} from '@/molecules/HomepageFormbar/interfaces';
import {KeyValuePair} from '@/molecules/KeyValuePair';
import {NumberInput} from '@/molecules/NumberInput';
import {NumberInputValue} from '@/molecules/NumberInput/interfaces';
import {PageHeader} from '@/molecules/PageHeader';
import {ProgressWheel} from '@/molecules/ProgressWheel';
import {PurchaseInfo} from '@/molecules/PurchaseInfo';
import {Searchbar} from '@/molecules/Searchbar';
import {SearchbarIProps} from '@/molecules/Searchbar/interfaces';
import {TaskListLinks} from '@/molecules/TaskListLinks';
import {TextInput} from '@/molecules/TextInput';
import {AppointmentHistory} from '@/organisms/AppointmentHistory';
import {appointmentHistoryMocks} from '@/organisms/AppointmentHistory/mock';
import {Benefits} from '@/organisms/Benefits';
import {FeedbackModal} from '@/organisms/FeedbackModal';
import {LinkedTasks} from '@/organisms/LinkedTasks';
import {LoyaltyBox} from '@/organisms/LoyaltyBox';
import {
  loyaltyBoxMockListSubscribing,
  loyaltyBoxMockListPopulatedActive,
  loyaltyBoxMockListPopulatedSuspended,
} from '@/organisms/LoyaltyBox/mock';
import {PersonalInformation} from '@/organisms/PersonalInformation';
import {randomCustomersResponse} from '@/organisms/PersonalInformation/mock';
import {PointsInput} from '@/organisms/PointsInput';
import {PurchasesDetails} from '@/organisms/PurchasesDetails';
import {lastPurchasesResponseMock} from '@/organisms/PurchasesDetails/mock';
import {ScriptBox} from '@/organisms/ScriptBox';
import {scriptBoxMock} from '@/organisms/ScriptBox/mock';
import {TaskListCard} from '@/organisms/TaskListCard';
import {
  randomTaskListCardInfoTypeBirthday,
  randomTaskListCardInfoTypeEE,
  randomTaskListCardInfoTypeQuote,
  randomTaskListCardInfoTypeTopClients,
} from '@/organisms/TaskListCard/mock';
import {TaskListFilters} from '@/organisms/TaskListFilters';
import {TeamMembers} from '@/organisms/TeamMembers';
import {BenefitsMock} from '@/store/benefit/mock';
import {SingleCampaign} from '@/store/campaigns';
import {useLocale} from '@/store/locale';
import {LINKED_TASKS_MOCK} from '@/store/tasks/mock';
import {Base} from '@/templates/Base';
import theme from '@/themes/theme';

dayjs.extend(Utc);

/** Component wrapper */
const ComponentBlock = ({
  title,
  children,
  sx,
}: {
  title: string;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}): JSX.Element => (
  <Box>
    <Box>
      <Typography variant="headline5" gutterBottom>
        {title}
      </Typography>
      <hr />
    </Box>
    <Box pb={7} pt={2} sx={sx}>
      {children}
    </Box>
  </Box>
);

const Components = (): JSX.Element => {
  const {t} = useTranslation();

  // End Data table

  // START Progress Wheel Management
  const [minProgressWheelValue, maxProgressWheelValue] = [0, 142];
  const [progressWheelValue, setProgressWheelValue] = useState(76);
  const handleProgressWheelChange = (
    event: Event,
    newValue: number | number[],
  ) => {
    setProgressWheelValue(newValue as number);
  };
  const progressWheelProps = {
    min: minProgressWheelValue,
    max: maxProgressWheelValue,
    value: progressWheelValue,
  };
  // END Progress Wheel Management

  // START Toast Config
  const {showErrorToast, showSuccessToast} = useToast();
  // END Toast Config

  // START Select Management
  const [emptySelected, setEmptySelected] = useState<SelectValueType>('');
  const options: SelectOptions = [
    {value: '8', label: 'Eight'},
    {value: '3', label: 'Three'},
    {value: '5', label: 'Five'},
  ];
  const [selected, setSelected] = useState(options[0].value);
  const [selected2, setSelected2] = useState(options[2].value);
  // END Select Management

  // START Dialog states
  const [dialogOpen, setDialogOpen] = useState(false);
  const [advancedDialogOpen, setAdvancedDialogOpen] = useState(false);
  const [advancedDialogReady, setAdvancedDialogReady] = useState(false);
  // END Dialog states

  // START Number Input State
  const [numberInput, setNumberInput] = useState<NumberInputValue>('');
  const [numberInput2, setNumberInput2] = useState<NumberInputValue>('');
  // END number Input State

  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const {formatDate} = useLocale();
  // END Data Picker

  // START Points Input
  const [points, setPoints] = useState<number | null>(null);
  console.log('POINTS', points);
  // END Points Input

  // START Home Formbar
  const [freeSearch, setFreeSearch] = useState('');
  const brandOptions: any = [
    {value: 'RayBan', label: 'RayBan'},
    {value: 'Luxottica', label: 'Luxottica'},
  ];
  const [brand, setBrand] = useState('');
  const countryOptions: any = [
    {value: 'EN-US', label: 'USA'},
    {value: 'FR-CA', label: 'CANADA'},
  ];
  const [country, setCountry] = useState('');
  const homeFormbarProps: HomepageFormBarProps = {
    q: freeSearch,
    onFreeSearchChange: newValue => setFreeSearch(newValue),
    bannerSearch: brand,
    bannerOptions: brandOptions,
    onBannerChange: newValue => setBrand(newValue),
    countrySearch: country,
    countryOptions,
    onCountryChange: newValue => setCountry(newValue),
    onSubmit: () => {
      console.log('SEARCH w/:', freeSearch, brand, country);
      setFreeSearch('');
      setBrand('');
      setCountry('');
    },
  };
  // END Home Formbar

  // START Searchbar
  const [searchbarText, setSearchbarText] = useState('');
  const searchbarProps: SearchbarIProps = {
    freeSearch: searchbarText,
    onFreeSearchChange: newValue => setSearchbarText(newValue),
    onReset: () => setSearchbarText(''),
    onSubmit: () => {
      console.log('SEARCH w/:', freeSearch);
      setSearchbarText('');
    },
  };
  // END Searchbar

  const badges = useMemo(
    () => [
      {
        badge: 'newCollection',
      },
      {
        badge: 'birthday',
      },
      {
        badge: 'callback',
      },
      {
        badge: 'quote',
      },
    ],
    [],
  );

  const purchaseCategories: Array<PURCHASE_CATEGORIES> = useMemo(
    () => [
      PURCHASE_CATEGORIES.LENSES,
      PURCHASE_CATEGORIES.APPAREL,
      PURCHASE_CATEGORIES.CONTACTS,
      PURCHASE_CATEGORIES.SUNGLASSES,
    ],
    [],
  );

  /*TODO: Call campaign API */
  const api = useMemo(
    () => [
      {
        type: 'birthday',
        userId: 'Store manager',
        startDate: '27th Apr 23',
        endDate: '05th May 23',
        taskNumber: 25,
        completedTask: 19,
      },
    ],
    [],
  );

  /*@TODO: Get Tasks assigned */
  const tasksAssigned = useMemo(
    () => [
      {
        taskNumber: 14,
        label: t('Campaign.taskList.assigned'),
      },
      {
        taskNumber: 56,
        label: t('Campaign.taskList.notAssigned'),
      },
    ],
    [t],
  );

  const [emailInput, setEmailInput] = useState('1234 ciao é una prova');

  const handleEmailInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newValue = e.target.value;
      // Only allow changes that retain the magic string:
      if (newValue.startsWith('1234')) {
        setEmailInput(newValue);
      }
    },
    [],
  );

  return (
    <Base loginNavbar>
      <div>
        <Text paragraph variant="headline1">
          Components
        </Text>

        {/* ------------------------------------- */}

        <ComponentBlock title="Email object input">
          <Container maxWidth="sm" disableGutters>
            <Input
              value={emailInput}
              onChange={handleEmailInputChange}
              type={'text'}
            />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Linked tasks full">
          <Container maxWidth="sm" disableGutters>
            <LinkedTasks loading={false} response={LINKED_TASKS_MOCK} />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Linked tasks empty">
          <Container maxWidth="sm" disableGutters>
            <LinkedTasks loading={false} response={[]} />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Appointment history full with taskId">
          <Container maxWidth="sm" disableGutters>
            <AppointmentHistory
              feedbackAppointmentType={'TAB_LITE'}
              handleCreateTaskAppointment={console.log}
              handleDisassociateAndAssociateTaskAppointment={console.log}
              taskId={'1234'}
              currentStoreId={'A106'}
              response={appointmentHistoryMocks}
            />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Appointment history no next appointments with taskId">
          <Container maxWidth="sm" disableGutters>
            <AppointmentHistory
              handleCreateTaskAppointment={console.log}
              handleDisassociateAndAssociateTaskAppointment={console.log}
              taskId={'1234'}
              response={{
                ...appointmentHistoryMocks,
                nextAppointments: [],
              }}
              currentStoreId={'A106'}
            />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Appointment history full">
          <Container maxWidth="sm" disableGutters>
            <AppointmentHistory
              handleCreateTaskAppointment={console.log}
              handleDisassociateAndAssociateTaskAppointment={console.log}
              response={appointmentHistoryMocks}
              currentStoreId={'A106'}
            />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Appointment history no next appointments">
          <Container maxWidth="sm" disableGutters>
            <AppointmentHistory
              handleCreateTaskAppointment={console.log}
              handleDisassociateAndAssociateTaskAppointment={console.log}
              response={{
                ...appointmentHistoryMocks,
                nextAppointments: [],
              }}
              currentStoreId={'A106'}
            />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Appointment history full readOnly">
          <Container maxWidth="sm" disableGutters>
            <AppointmentHistory
              handleCreateTaskAppointment={console.log}
              handleDisassociateAndAssociateTaskAppointment={console.log}
              readOnly={true}
              response={appointmentHistoryMocks}
              currentStoreId={'A106'}
            />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Appointment history no next appointments readOnly">
          <Container maxWidth="sm" disableGutters>
            <AppointmentHistory
              handleCreateTaskAppointment={console.log}
              handleDisassociateAndAssociateTaskAppointment={console.log}
              readOnly={true}
              response={{
                ...appointmentHistoryMocks,
                nextAppointments: [],
              }}
              currentStoreId={'A106'}
            />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Appointment history empty">
          <Container maxWidth="sm" disableGutters>
            <AppointmentHistory
              handleCreateTaskAppointment={console.log}
              handleDisassociateAndAssociateTaskAppointment={console.log}
              readOnly={true}
              response={{
                nextAppointments: [],
                pastAppointments: [],
              }}
              currentStoreId={'A106'}
            />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Loyalty active">
          <Container maxWidth="sm" disableGutters>
            <LoyaltyBox
              origin={'task'}
              response={loyaltyBoxMockListPopulatedActive}
              loading={false}
              bannerSearch={'SV'}
              countrySearch={'IT'}
              subscribeCB={console.log}
            />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Loyalty suspended">
          <Container maxWidth="sm" disableGutters>
            <LoyaltyBox
              origin={'task'}
              loading={false}
              response={loyaltyBoxMockListPopulatedSuspended}
              bannerSearch={'SV'}
              countrySearch={'IT'}
              subscribeCB={console.log}
            />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Loyalty subscribing">
          <Container maxWidth="sm" disableGutters>
            <LoyaltyBox
              origin={'task'}
              loading={false}
              response={loyaltyBoxMockListSubscribing}
              bannerSearch={'SV'}
              countrySearch={'IT'}
              subscribeCB={console.log}
            />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Benefits">
          <Container maxWidth="sm" disableGutters>
            <Benefits response={BenefitsMock} />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Benefits both empty">
          <Container maxWidth="sm" disableGutters>
            <Benefits response={{benefits: [], notifications: []}} />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Benefits promo empty">
          <Container maxWidth="sm" disableGutters>
            <Benefits
              response={{
                benefits: [],
                notifications: BenefitsMock.notifications,
              }}
            />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Benefits notifications empty">
          <Container maxWidth="sm" disableGutters>
            <Benefits
              response={{
                benefits: BenefitsMock.benefits,
                notifications: [],
              }}
            />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Script">
          <Container maxWidth="sm" disableGutters>
            <ScriptBox response={scriptBoxMock} />
          </Container>
        </ComponentBlock>
        <ComponentBlock title="Purchases details">
          <Container maxWidth="sm" disableGutters>
            <PurchasesDetails
              country={'GB'}
              response={lastPurchasesResponseMock}
              viewPurchaseHistoryCB={console.log}
            />
          </Container>
        </ComponentBlock>
        <ComponentBlock title="Personal information SM AM RM">
          <Container maxWidth="sm" disableGutters>
            <PersonalInformation
              error={false}
              customersResponse={randomCustomersResponse}
              enableCustomersList
              loading={false}
            />
          </Container>
        </ComponentBlock>
        <ComponentBlock title="Personal information CC">
          <Container maxWidth="sm" disableGutters>
            <PersonalInformation
              error={false}
              customersResponse={randomCustomersResponse}
              loading={false}
            />
          </Container>
        </ComponentBlock>
        <ComponentBlock title="Context Menu">
          <ContextMenu
            label={t('ContextMenu.sortBy')}
            iconName={'chevron_down'}
            iconSize={14}
            subMenuItems={[
              {
                id: 'submenu-item-one',
                label: t('ContextMenu.subMenuItems.newestToOldest'),
                onClick: () =>
                  console.log(
                    'Submenu item 1' /* @TODO: Sorting Newest to Oldest*/,
                  ),
              },
              {
                id: 'submenu-item-two',
                label: t('ContextMenu.subMenuItems.oldestToNewest'),
                onClick: () =>
                  console.log(
                    'Submenu item 2' /* @TODO: Sorting Oldest to Newest*/,
                  ),
              },
            ]}
          />
        </ComponentBlock>

        {/* ------------------------------------- */}

        {/*@TODO: Get tasks assigned from api */}
        <ComponentBlock title="Report Card">
          {tasksAssigned.map(({taskNumber, label}, i) => (
            <TaskListLinks
              key={`${i}`}
              taskNumber={taskNumber}
              label={label}
              onClick={console.log}
            />
          ))}
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Report Card">
          <LuxCard
            campaignColor="red"
            startDate="2023-02-01"
            endDate="2023-07-31"
            numericList={[
              {
                label: t('Campaign.report.taskCompleted'),
                value: `${19}/${25}`,
              },
              {
                label: t('Campaign.report.contacted'),
                value: 14,
              },
              {
                label: t('Campaign.report.positive'),
                value: 4,
              },
              {
                label: t('Campaign.report.appointments'),
                value: 5,
              },
            ]}
          />
        </ComponentBlock>

        {/* ------------------------------------- */}

        {/*@TODO: Get info from api */}
        <ComponentBlock title="Team Member">
          <TeamMembers isLoading={false} members={[]} />
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Campaign Card">
          {api.map(item => (
            <CampaignCard campaign={item as SingleCampaign} />
          ))}
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Badge Campaigns">
          {badges.map(({badge}: any) => (
            <Badge key={badge} variant={'tiny'} />
          ))}
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Badge Task List">
          {badges.map(({badge}: any) => (
            <Badge key={badge} variant={'xxs'} />
          ))}
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock
          title="Customer Balance Card"
          sx={{backgroundColor: theme.palette.grey[600]}}>
          <Stack alignItems="center">
            {/*<CustomerBalance {...randomUserBalance} />*/}
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Purchase Info">
          <Stack
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
            maxWidth="calc(100% - 40px)"
            marginLeft="auto"
            marginRight="auto">
            <PurchaseInfo
              lastPurchase="2023-04-15T16:47:58.000Z"
              customerSegment="EXPLORER"
              purchaseCategory={[
                PURCHASE_CATEGORIES.FRAMES,
                PURCHASE_CATEGORIES.STORE_SERVICES,
                PURCHASE_CATEGORIES.SUNGLASSES,
              ]}
              myAccount={true}
              email="gloglo.619t@gmail.com"></PurchaseInfo>
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock
          title="Customer Info Card"
          sx={{backgroundColor: theme.palette.grey[600]}}>
          <Stack alignItems="center">
            {/*<CustomerInfo {...createFakeExtendedUser()} />*/}
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock
          title="Panel Toggle"
          sx={{backgroundColor: theme.palette.grey[600]}}>
          <Stack alignItems="center">
            <PanelToggle summaryText={t('ProductCard.togglePanelShowLens')}>
              <Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Text>
            </PanelToggle>
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock
          title="Task list - Card"
          sx={{backgroundColor: theme.palette.grey[600]}}>
          <Grid
            container
            rowSpacing={{xs: 1.6, md: 4}}
            columnSpacing={{xs: 1.6, md: 2}}>
            <Grid item xs={4} md={12 / 5}>
              <TaskListCard {...randomTaskListCardInfoTypeTopClients} />
            </Grid>
            <Grid item xs={4} md={12 / 5}>
              <TaskListCard {...randomTaskListCardInfoTypeTopClients} />
            </Grid>
            <Grid item xs={4} md={12 / 5}>
              <TaskListCard {...randomTaskListCardInfoTypeEE} />
            </Grid>
            <Grid item xs={4} md={12 / 5}>
              <TaskListCard {...randomTaskListCardInfoTypeEE} />
            </Grid>
            <Grid item xs={4} md={12 / 5}>
              <TaskListCard {...randomTaskListCardInfoTypeBirthday} />
            </Grid>
            <Grid item xs={4} md={12 / 5}>
              <TaskListCard {...randomTaskListCardInfoTypeBirthday} />
            </Grid>
            <Grid item xs={4} md={12 / 5}>
              <TaskListCard {...randomTaskListCardInfoTypeBirthday} />
            </Grid>
            <Grid item xs={4} md={12 / 5}>
              <TaskListCard {...randomTaskListCardInfoTypeQuote} />
            </Grid>
          </Grid>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Date Picker">
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            maxWidth="30%">
            <Button variant="primary" onClick={() => setOpenDatePicker(true)}>
              Apri il calendario
            </Button>
            {openDatePicker && (
              <DatePicker
                open={openDatePicker}
                onClose={() => setOpenDatePicker(false)}
                startDate={formatDate('27-04-2023')}
                endDate={formatDate('08-05-2023')}
                onSave={() => {
                  /* @TODO: Save Date */
                }}
              />
            )}
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Task List Filters">
          <TaskListFilters
            allFilterId={''}
            filters={[]}
            selectedFilters={[]}
            onChange={console.log}
            onOrderChange={console.log}
          />
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Chips">
          <Box>
            <FiltersChips
              allFilterId="points-history-tab"
              filters={[
                {
                  id: 'points-history-tab',
                  label: 'Points History',
                },
                {
                  id: 'reward-list-tab',
                  label: 'Reward list',
                },
                {
                  id: 'mission-tab',
                  label: 'Mission',
                },
              ]}
              activeFilters={['points-history-tab']}
              onChange={console.log}
            />
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Tabs">
          <Text paragraph variant="headline1" medium>
            Campaigns
          </Text>
          <Box>
            <Tabs
              mainTabId="active-only-tab"
              tabs={[
                {
                  id: 'active-only-tab',
                  label: 'Active only',
                },
                {
                  id: 'all-campaigns-tab',
                  label: 'All campaigns',
                },
              ]}
            />
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Nested Tabs">
          <Text paragraph variant="headline1" medium>
            Task List
          </Text>
          <Box>
            <Tabs
              mainTabId="assigned-to-me-tab"
              subTabId="to-do-tab"
              tabs={[
                {
                  id: 'assigned-to-me-tab',
                  label: 'Assigned to me',
                  tabsSecondLevel: [
                    {
                      id: 'to-do-tab',
                      label: 'To do',
                    },
                    {
                      id: 'completed-tab',
                      label: 'Completed',
                    },
                  ],
                },
                {
                  id: 'all-tasks-tab',
                  label: 'All tasks',
                  tabsSecondLevel: [
                    {
                      id: 'not-assigned-tab',
                      label: 'Not assigned',
                    },
                    {
                      id: 'assigned-tab',
                      label: 'Assigned',
                    },
                    {
                      id: 'contacted-tab',
                      label: 'Contacted',
                    },
                  ],
                },
              ]}
            />
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Search Result Card">
          <Stack alignItems="center">
            {/* <SearchResult {...randomSearchResult} /> */}
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Key Value Pair">
          <Stack spacing={8} direction="row" justifyContent="center">
            <KeyValuePair keyLabel="Variant" value="Normal" />
            <KeyValuePair keyLabel="Variant" value="Small" small />
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Home Formbar">
          <Box>
            <HomepageFormbar {...homeFormbarProps} />
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Searchbar">
          <Box>
            <Searchbar {...searchbarProps} />
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Points Input">
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Box sx={{width: 365}}>
              <PointsInput
                min={0}
                value={points}
                onChange={newValue => setPoints(newValue)}
                currentPoints={100}
                max={50}
              />
            </Box>
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Number Input">
          <Stack spacing={2} direction="row" justifyContent="center">
            <Box sx={{width: 400}}>
              <NumberInput
                label="Basic Number Input"
                value={numberInput}
                onChange={newValue => setNumberInput(newValue)}
              />
            </Box>
            <Box sx={{width: 400}}>
              <NumberInput
                label="Number Input with Min/Max values (30-100)"
                min={30}
                max={100}
                value={numberInput2}
                onChange={newValue => setNumberInput2(newValue)}
              />
            </Box>
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Select">
          <Stack spacing={2} direction="row">
            <Select
              label="Empty Select"
              options={options}
              value={emptySelected}
              onChange={e => setEmptySelected(e?.target.value ?? '')}
              placeholder="Choose a value"
            />
            <Select
              label="Basic Select"
              options={options}
              value={selected}
              onChange={e => setSelected(e?.target.value ?? '')}
            />
            <Select
              label="Basic Select w/ error"
              options={options}
              value={selected2}
              onChange={e => setSelected2(e?.target.value ?? '')}
              error={selected2 === options[2].value ? 'This is wrong' : ''}
            />
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Dialog">
          <Stack spacing={2} direction="row" justifyContent="center">
            <Button onClick={() => setDialogOpen(true)} variant="primary">
              Open Basic Dialog
            </Button>
            <Dialog
              open={dialogOpen}
              dialogTitle="Edit Customer Balance?"
              dialogActions={
                <CommonButtons
                  onClose={() => setDialogOpen(false)}
                  onConfirm={() => setDialogOpen(false)}
                  confirmText="Confirm"
                />
              }
            />
            <Button
              onClick={() => setAdvancedDialogOpen(true)}
              variant="primary">
              Open Advanced Dialog
            </Button>
            <Dialog
              open={advancedDialogOpen}
              dialogTitle="Edit Customer Balance?"
              dialogContent={
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={advancedDialogReady}
                      onChange={(_, checked) => setAdvancedDialogReady(checked)}
                    />
                  }
                  label="Everything Ready"
                />
              }
              dialogActions={
                <CommonButtons
                  onClose={() => setAdvancedDialogOpen(false)}
                  onConfirm={() => setAdvancedDialogOpen(false)}
                  confirmText="Confirm"
                  confirmDisabled={!advancedDialogReady}
                />
              }
            />
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Page Header">
          <Stack spacing={2}>
            <PageHeader
              title={t('PageHeader.loyaltyProfileManagement')}
              user={randomLoggedUser}
            />
            <PageHeader title="" user={randomLoggedUser} />
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="User Avatar">
          <Box
            sx={{
              backgroundColor: 'black',
              height: 60,
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Stack
              spacing={2}
              direction="row"
              divider={
                <Divider
                  sx={{backgroundColor: 'white'}}
                  orientation="vertical"
                  flexItem
                />
              }>
              {randomLoggedUsers.map((user, i) => (
                <UserAvatar key={i} user={user} />
              ))}
            </Stack>
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Toast">
          <Stack spacing={2} direction="row" justifyContent="center">
            <Button
              onClick={() =>
                showSuccessToast(
                  'Your edit request has being succesfully processed',
                )
              }
              variant="primary">
              Success Toast
            </Button>
            <Button
              onClick={() =>
                showErrorToast('Something went wrong, please try again')
              }
              variant="primary">
              Error Toast
            </Button>
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Progress Wheel">
          <Stack spacing={2} alignItems="center">
            <Slider
              sx={{width: '200px'}}
              onChange={handleProgressWheelChange}
              {...progressWheelProps}
            />
            <ProgressWheel
              {...progressWheelProps}
              currentMin={0}
              nextMin={100}
              pointsToNextTier={0}
            />
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Value Circle">
          <ValueCircle value={5} unit="Tier" />
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Form">
          <Box style={{display: 'flex', gap: 20, alignItems: 'flex-start'}}>
            <Box style={{width: 365}}>
              <TextInput label="Text input" placeholder="Text input" />
            </Box>
            <Box style={{width: 365}}>
              <TextInput
                label="Text input con errore"
                placeholder="Text input con errore"
                error="Campo obbligatorio"
              />
            </Box>
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Buttons">
          <Box style={{display: 'flex', gap: 20, alignItems: 'center'}}>
            <Button variant="primary">Primary</Button>
            <Button
              variant="primary"
              endIcon={
                <IconSVG
                  icon="resend_email"
                  size={22}
                  color={theme.palette.common.white}
                />
              }>
              Primary with icon
            </Button>
            <Button variant="primary" disabled>
              Primary disabled
            </Button>
            <Button variant="secondary">Secondary</Button>
            <Button variant="secondary" disabled>
              Secondary disabled
            </Button>
            <Button variant="tertiary">Tertiary</Button>
            <Button variant="tertiary" disabled>
              Tertiary disabled
            </Button>
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Tooltip">
          <Box display="flex" justifyContent="center" alignItems="center">
            <TooltipInfo title="Reason of balance edit" />
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Links">
          <Box style={{display: 'flex', gap: 20, alignItems: 'flex-start'}}>
            <Link linkVariant="dark" textVariant="headline4" href="#">
              Simple link dark
            </Link>
            <Link linkVariant="light" textVariant="headline4" href="#">
              Simple link light
            </Link>
            <Link
              linkVariant="dark"
              textVariant="headline4"
              href="#"
              iconEnd={<IconSVG icon="arrow_right_2" size={24} color="#fff" />}>
              View purchase history
            </Link>
            <Link
              linkVariant="light"
              textVariant="headline4"
              href="#"
              iconEnd={<IconSVG icon="arrow_right_2" size={24} color="#000" />}>
              View purchase history
            </Link>
            <Link
              linkVariant="light"
              textVariant="headline4"
              href="#"
              iconStart={
                <IconSVG icon="arrow_left_2" size={24} color="#000" />
              }>
              Back
            </Link>
            <Link
              linkVariant="dark"
              textVariant="headline4"
              href="#"
              iconStart={
                <IconSVG icon="arrow_left_2" size={24} color="#fff" />
              }>
              Back
            </Link>
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Colors">
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '25px',
            }}>
            {[
              {
                palette: 'common.black',
                name: 'Black',
              },
              {
                palette: 'common.white',
                name: 'White',
              },
              {
                palette: 'primary.main',
                name: 'Primary main',
              },
              {
                palette: 'primary.disabled',
                name: 'Primary disabled',
              },
              {
                palette: 'secondary.main',
                name: 'Secondary main',
              },
              {
                palette: 'tertiary.main',
                name: 'Tertiary main',
              },
              {
                palette: 'error.main',
                name: 'Error main',
              },
              {
                palette: 'success.main',
                name: 'Success main',
              },
              {
                palette: 'grey.900',
                name: 'Grey 900',
              },
              {
                palette: 'grey.800',
                name: 'Grey 800',
              },
              {
                palette: 'grey.700',
                name: 'Grey 700',
              },
              {
                palette: 'grey.600',
                name: 'Grey 600',
              },
              {
                palette: 'grey.500',
                name: 'Grey 500',
              },
              {
                palette: 'grey.300',
                name: 'Grey 300',
              },
            ].map(color => (
              <Box key={color.name}>
                <Paper
                  sx={{
                    backgroundColor: color.palette,
                    width: 100,
                    height: 100,
                  }}
                />
                <Text variant="body">{color.name}</Text>
              </Box>
            ))}
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}
        <ComponentBlock title="Feedback Modal">
          <FeedbackModal>
            <Button variant="primary">apri feedback</Button>
          </FeedbackModal>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Tag member/guest">
          <Stack spacing={2}>
            <Tag variant="member" />
            <Tag variant="guest" />
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Star rating">
          <Stack spacing={2}>
            <StarRating value={1} />
            <StarRating value={2} />
            <StarRating value={3} />
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Purchase categories">
          <PurchaseCategory categories={purchaseCategories}></PurchaseCategory>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Typography">
          <Stack spacing={2}>
            <Text paragraph variant="headline1">
              Headline1
            </Text>
            <Text paragraph variant="headline2">
              Headline2
            </Text>
            <Text paragraph variant="headline3">
              Headline3
            </Text>
            <Text paragraph variant="headline5">
              Headline4
            </Text>
            <Text paragraph variant="body">
              Body
            </Text>
          </Stack>
        </ComponentBlock>
        <ComponentBlock title="Icons">
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '25px',
            }}>
            {[
              'view_on',
              'view_off',
              'arrow_left',
              'arrow_right',
              'add',
              'chevron_down',
              'chevron_left',
              'chevron_right',
              'close',
              'deactivate_card',
              'edit',
              'export',
              'filter',
              'remove',
              'resend_card',
              'resend_email',
              'search',
              'alert_circle',
              'tier_benefits',
              'action',
              'reset',
              'messages',
              'shop',
              'logout',
              'cross_circle',
              'search_no_results',
              'card_actions',
              'arrow_left_2',
              'arrow_right_2',
              'star',
              'lens_glasses',
              'lens_sunglasses',
              'clothes',
              'retail',
              'contact',
              'calendar',
              'plus_circle',
              'minus_circle',
              'user',
              'reorder',
              'assign_to_me',
              'accordation_close',
              'accordation_open',
              'clock',
              'customer_information',
              'customer_profile',
              'error',
              'eye_checkup',
              'glasses',
              'lens_contact',
              'lenses',
              'manage_consents',
              'sunglasses',
              'text',
              'web',
            ].map(iconName => (
              <Paper
                key={iconName}
                elevation={0}
                square
                sx={{textAlign: 'center'}}>
                <Text>
                  <IconSVG
                    icon={iconName as IconNames}
                    size={24}
                    color="#000"
                  />
                  <br />
                  {iconName}
                </Text>
              </Paper>
            ))}
          </Box>
        </ComponentBlock>
      </div>
    </Base>
  );
};

export default React.memo(Components);

import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyles = () => {
  const theme = useTheme();

  const appointmentList = useMemo<SxProps<Theme>>(
    () => ({
      flexDirection: 'column',
      gap: theme.spacing(1.6),
      width: '100%',
      '&.closed': {
        overflow: 'hidden',
      },
      '&.open': {
        overflow: 'scroll',
        scrollbarWidth: 'none',
      },
    }),
    [theme],
  );

  const tabLite = useMemo<SxProps<Theme>>(
    () => ({
      width: 'calc(100vw - 112px)',
      height: 'calc(100dvh - 128px - env(safe-area-inset-bottom))',
      '@-moz-document url-prefix()': {
        height: 'calc(100vh - 128px - env(safe-area-inset-bottom))',
      },
      position: 'relative',
      '.scheduler-widget': {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        '.page-content': {
          minHeight: `calc(100dvh - 188px - env(safe-area-inset-bottom))`,
          '@-moz-document url-prefix()': {
            minHeight: `calc(100vh - 188px - env(safe-area-inset-bottom))`,
          },
        },
        '.confirmation-page': {
          maxHeight: `calc(100dvh - 188px - env(safe-area-inset-bottom))`,
          '@-moz-document url-prefix()': {
            maxHeight: `calc(100vh - 188px - env(safe-area-inset-bottom))`,
          },
        },
      },
    }),
    [],
  );

  const card = useMemo<SxProps<Theme>>(
    () => ({
      flexDirection: 'column',
      gap: theme.spacing(1.6),
      padding: theme.spacing(1.2, 1.6),
      borderRadius: theme.spacing(1.2),
      backgroundColor: Colors.GreyBackground,
      '&.appointmentCard': {
        '&__NEXT': {
          border: `${theme.spacing(0.1)} solid ${Colors.Black}`,
        },
        '&__PAST': {
          border: `${theme.spacing(0.1)} solid ${Colors.GreyBackground}`,
        },
        '&__ASSIGN': {
          border: `${theme.spacing(0.1)} solid ${Colors.Green}`,
          '.cardTitle': {
            color: Colors.Green,
          },
        },
        '&__DELETE': {
          border: `${theme.spacing(0.1)} solid ${Colors.ErrorLight}`,
          '.cardTitle': {
            color: Colors.ErrorLight,
          },
          '.appointmentDateLabel': {
            textDecoration: 'line-through',
          },
        },
      },
      '.scheduledLabel': {
        display: 'flex',
        alignItems: 'center',
        color: Colors.Green,
        '&:before': {
          content: '""',
          display: 'inline-block',
          width: theme.spacing(0.6),
          height: theme.spacing(0.6),
          borderRadius: '50%',
          backgroundColor: Colors.Green,
          marginRight: theme.spacing(0.8),
        },
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({appointmentList, tabLite, card}),
    [appointmentList, tabLite, card],
  );
};

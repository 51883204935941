import {useCallback, useMemo} from 'react';

import {GridRowId} from '@mui/x-data-grid';
import * as Sentry from '@sentry/react';

import {useAPI} from '@/store/api';
import {useApiPath, APIS} from '@/store/api/useApiPath';
import {
  GetAssignedTasksParams,
  GetAssignedTasksResponse,
  GetLastPurchasesResponse,
  GetLastPurchasesParams,
  GetScriptParams,
  GetScriptResponse,
  GetTaskHeaderDetailsParams,
  GetTaskHeaderDetailsResponse,
  PostLinkedTasksParams,
  PostLinkedTasksResponse,
} from '@/store/tasks/types';

export const useTasks = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  const getTaskCount = useCallback(
    async ({storeId, ...params}: GetAssignedTasksParams) => {
      try {
        const {data} = await api.get<GetAssignedTasksResponse>(
          getApiPath(APIS.STORES_STOREID_TASKS_KPI, {storeId}),
          {
            params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const assignTask = useCallback(
    async (
      storeId: number | string,
      taskIds: GridRowId[] | null,
      assigneId: number | string,
    ) => {
      try {
        const {data} = await api.patch(
          getApiPath(APIS.STORES_STOREID_TASKS_ASSIGNTOTASK, {storeId}),
          {
            taskIds: taskIds,
            assigneId: assigneId,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const assignTaskToMe = useCallback(
    async (storeId: number | string, taskIds: GridRowId[]) => {
      try {
        const {data} = await api.patch(
          getApiPath(APIS.STORES_STOREID_TASKS_ASSIGNTOME, {storeId}),
          {
            taskIds: taskIds,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getLastPurchases = useCallback(
    async ({storeId, taskId, ...params}: GetLastPurchasesParams) => {
      try {
        const {data} = await api.get<GetLastPurchasesResponse>(
          getApiPath(APIS.STORES_STOREID_TASKS_TASKID_PURCHASES, {
            storeId,
            taskId,
          }),
          {params},
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getScript = useCallback(
    async ({taskId, ...params}: GetScriptParams) => {
      try {
        const {data} = await api.post<GetScriptResponse>(
          getApiPath(APIS.TASKS_TASKID_SCRIPT, {
            taskId,
          }),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getTaskHeaderDetails = useCallback(
    async ({taskId}: GetTaskHeaderDetailsParams) => {
      try {
        const {data} = await api.get<GetTaskHeaderDetailsResponse>(
          getApiPath(APIS.TASKS_TASKID, {taskId}),
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getLinkedTasks = useCallback(
    async ({...params}: PostLinkedTasksParams) => {
      try {
        const {data} = await api.post<PostLinkedTasksResponse>(
          getApiPath(APIS.V1_TASKS_LINKED),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  return useMemo(() => {
    return {
      getTaskCount,
      assignTask,
      assignTaskToMe,
      getLastPurchases,
      getScript,
      getTaskHeaderDetails,
      getLinkedTasks,
    };
  }, [
    getTaskCount,
    assignTask,
    assignTaskToMe,
    getLastPurchases,
    getScript,
    getTaskHeaderDetails,
    getLinkedTasks,
  ]);
};

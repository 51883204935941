import React, {useState, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {Skeleton} from '@mui/material';
import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import dayjs from 'dayjs';

import {DataTable} from '@/atoms/DataTable';
import {BaseRouterParams} from '@/definitions/base';
import {useToast} from '@/hooks/toast';
import {FilterDialog} from '@/molecules/FilterDialog';
import {useAuthentication} from '@/store/authentication';
import {
  MissionsListSetFilters,
  MissionsListReset,
} from '@/store/loyaltyFilters/actions';
import {MissionsListFilter} from '@/store/loyaltyFilters/missionsList.reducer';
import {selectorMissionsList} from '@/store/loyaltyFilters/selectors';
import {useMission} from '@/store/mission';

import {FilterMissionForm} from './FilterMissionForm';
import {useColumnDef} from './columnDef';
import {IMissionTable} from './interfaces';

const MissionTable = ({enabledApis, canExport}: IMissionTable) => {
  const {MissionListColumnDef} = useColumnDef();
  const {userStoreId} = useAuthentication();
  const {cardNumber, banner, country} = useParams<BaseRouterParams>();
  const {getMissionList, exportMission} = useMission();
  const {showSuccessToast, showErrorToast} = useToast();
  const {t} = useTranslation();

  const missionsListFilters = useSelector<
    {missionsListFilter: MissionsListFilter},
    MissionsListFilter
  >(selectorMissionsList);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState<Partial<MissionsListFilter>>();
  const [formValid, setFormValid] = useState<boolean>(false);

  /** Handle export and filter functions*/
  const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
  const [exportEnabled, setExportEnabled] = useState(false);

  const toggleFilterDialog = useCallback((value?: boolean) => {
    setOpenFilterDialog(prevState => {
      return typeof value !== 'undefined' ? value : !prevState;
    });
  }, []);

  const applyFilters = useCallback(() => {
    dispatch(MissionsListSetFilters(formData!));
    toggleFilterDialog(false);
  }, [dispatch, formData, toggleFilterDialog]);

  const handleTableReset = useCallback(() => {
    dispatch(MissionsListReset());
  }, [dispatch]);

  const apiPayload = useMemo(() => {
    return {
      ...(missionsListFilters.mission !== '' && {
        missionCode: missionsListFilters.mission,
      }),
      ...(missionsListFilters.status !== '' && {
        status: missionsListFilters.status,
      }),
      ...(missionsListFilters.dateFrom && {
        startDate: dayjs(missionsListFilters.dateFrom).format('YYYY-MM-DD'),
      }),
      ...(missionsListFilters.dateTo && {
        endDate: dayjs(missionsListFilters.dateTo).format('YYYY-MM-DD'),
      }),
    };
  }, [
    missionsListFilters.dateFrom,
    missionsListFilters.dateTo,
    missionsListFilters.mission,
    missionsListFilters.status,
  ]);

  const {
    data: Missions,
    fetchNextPage,
    isLoading,
    hasNextPage,
  } = useInfiniteQuery(
    ['missions', cardNumber, banner, country, apiPayload, userStoreId],
    ({pageParam = 1}) =>
      getMissionList({
        cardNumberSearch: cardNumber,
        bannerSearch: banner,
        countrySearch: country,
        ...apiPayload,
        page: pageParam,
        limit: 10,
      }),
    {
      getNextPageParam: ({metadataDTO: {page, total_pages}}) =>
        page >= total_pages ? undefined : page + 1,
      enabled: enabledApis,
    },
  );

  // Merge infiniteQuery results in a single list
  const missionsResponse = useMemo(
    () => Missions?.pages.flatMap(({data}) => data),
    [Missions],
  );

  const rows = useMemo(
    () =>
      missionsResponse?.map(item => {
        return {
          ...item,
          status: item.status.name,
          statusColor: item.status.color,
        };
      }) || [],
    [missionsResponse],
  );

  useQuery(
    ['rewardsExport', cardNumber, banner, country, apiPayload],
    () =>
      exportMission({
        cardNumberSearch: cardNumber,
        bannerSearch: banner,
        countrySearch: country,
        ...apiPayload,
      }),
    {
      enabled: exportEnabled,
      retry: false,
      onSuccess: data => {
        showSuccessToast(t('CustomerProfile.dataTable.toast.export.success'));
      },
      onError: () => {
        showErrorToast(t('CustomerProfile.dataTable.toast.export.error'));
      },
      onSettled: () => {
        setExportEnabled(false);
      },
    },
  );

  const handleExport = useCallback(() => {
    setExportEnabled(true);
  }, []);

  const filterNumber = useMemo(() => {
    let filterCopy = {...missionsListFilters};
    delete filterCopy.dateTo;

    return Object.values(filterCopy).filter(
      val => val !== '' && typeof val !== 'undefined',
    ).length;
  }, [missionsListFilters]);

  const canResetFilters = useMemo(() => {
    return Boolean(
      Object.values(missionsListFilters).find(
        val => val !== '' && typeof val !== 'undefined',
      ),
    );
  }, [missionsListFilters]);

  return (
    <>
      {isLoading || !rows ? (
        <Skeleton
          height={400}
          width="100%"
          animation="wave"
          variant="rounded"
        />
      ) : (
        <DataTable
          columnDef={MissionListColumnDef}
          data={rows ?? []}
          maxHeight="450px"
          handleExport={handleExport}
          handleFilter={() => toggleFilterDialog(true)}
          handleFiltersReset={handleTableReset}
          fetchNextPage={fetchNextPage}
          isLoadingMore={hasNextPage}
          columnVisibility={{actions: false}}
          canExport={canExport}
          canResetFilters={canResetFilters}
          filterNumber={filterNumber}
        />
      )}
      <FilterDialog
        open={openFilterDialog}
        onClose={() => toggleFilterDialog(false)}
        onApply={applyFilters}
        confirmDisabled={!formValid}
        dialogContent={
          <FilterMissionForm
            filters={missionsListFilters}
            formCB={formState => setFormData(formState)}
            validCB={isValid => setFormValid(isValid)}
          />
        }
      />
    </>
  );
};

export default React.memo(MissionTable);

import React, {useMemo, useState, useRef, useEffect, useCallback} from 'react';
import scriptLoader from 'react-async-script-loader';
import {useTranslation} from 'react-i18next';
import {useInView} from 'react-intersection-observer';

import {Skeleton, Box, Stack, Button} from '@mui/material';
import dayjs from 'dayjs';

import {BodyText} from '@/atoms/BodyText';
import {Card} from '@/atoms/Card';
import {HeadlineText} from '@/atoms/HeadlineText';
import {IconSVG} from '@/atoms/IconSVG';
import {LabelValue} from '@/atoms/LabelValue';
import {LineText} from '@/atoms/LineText';
import {Modal} from '@/atoms/Modal';
import {XSText} from '@/atoms/XSText';
import {UserAppointmentType} from '@/definitions/user';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {useLinkToTaskModal} from '@/hooks/useLinkToTaskModal';
import useRelatedProductsModal from '@/hooks/useRelateProductsModal/useRelatedProductsModal';
import {tabLiteBasicConfiguration, capitalizeWords} from '@/lib/utils';
import {CustomAccordion} from '@/molecules/CustomAccordion';
import {
  AppointmentHistoryProps,
  AppointmentHistoryResponses,
} from '@/organisms/AppointmentHistory/interfaces';
import {useStyles} from '@/organisms/AppointmentHistory/styles';
import {AppointmentV2, WidgetResponseV2} from '@/store/appointments/v2';
import {useAuthentication} from '@/store/authentication';

import {queryClient} from '../../../index';

const AppointmentHistory = ({
  feedbackAppointmentType,
  response,
  loading,
  customer,
  taskIsEditable = true,
  readOnly = false,
  taskId = undefined,
  currentStoreId,
  currentAppointment,
  handleCreateTaskAppointment,
  handleDisassociateAndAssociateTaskAppointment,
  taskHeaderData,
}: AppointmentHistoryProps) => {
  /**
   * State to manage the accordion open/close
   */
  const [isOpen, setIsOpen] = useState<boolean>(false);

  /**
   * Check if the accordion should be disabled
   */
  const disableClick = useMemo(() => {
    return !response && loading;
  }, [response, loading]);

  const {t} = useTranslation();

  const renderContent = useMemo((): JSX.Element => {
    if (!response && loading) {
      return <Skeleton height="87px" animation="wave" variant="rounded" />;
    }

    if (!isOpen || !response) return <Box />;

    return (
      <AppointmentList
        loading={loading}
        feedbackAppointmentType={feedbackAppointmentType}
        response={response}
        customer={customer}
        taskIsEditable={taskIsEditable}
        readOnly={readOnly}
        taskId={taskId}
        currentStoreId={currentStoreId}
        currentAppointment={currentAppointment}
        handleCreateTaskAppointment={handleCreateTaskAppointment}
        handleDisassociateAndAssociateTaskAppointment={
          handleDisassociateAndAssociateTaskAppointment
        }
        taskHeaderData={taskHeaderData}
      />
    );
  }, [
    response,
    loading,
    isOpen,
    feedbackAppointmentType,
    customer,
    taskIsEditable,
    readOnly,
    taskId,
    currentStoreId,
    currentAppointment,
    handleCreateTaskAppointment,
    handleDisassociateAndAssociateTaskAppointment,
    taskHeaderData,
  ]);

  return (
    <>
      <Card id={'appointmentHistoryCard'}>
        <CustomAccordion
          bottomContent
          labelAlign={'center'}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          disableClick={disableClick}
          hideAccordionLabel={disableClick}
          accordionTitle={
            <HeadlineText superHeavy>
              {t('CustomerProfile.AppointmentHistory.title')}
            </HeadlineText>
          }
          expandedContent={() => renderContent}
        />
      </Card>
    </>
  );
};
export default React.memo(
  scriptLoader([
    process.env.REACT_APP_WIDGET_SRC,
    process.env.REACT_APP_WIDGET_POLYFILL_SRC,
    process.env.REACT_APP_WIDGET_UTILS_SRC,
  ])(AppointmentHistory),
);

interface AppointmentListProps
  extends Pick<
    AppointmentHistoryProps,
    | 'loading'
    | 'feedbackAppointmentType'
    | 'customer'
    | 'taskId'
    | 'currentStoreId'
    | 'currentAppointment'
    | 'handleCreateTaskAppointment'
    | 'taskHeaderData'
    | 'handleDisassociateAndAssociateTaskAppointment'
  > {
  response: AppointmentHistoryResponses;
  taskIsEditable: boolean;
  readOnly: boolean;
}

const AppointmentList = React.memo(
  ({
    loading,
    feedbackAppointmentType,
    response,
    customer,
    taskIsEditable,
    readOnly,
    taskId,
    currentStoreId,
    currentAppointment,
    taskHeaderData,
    handleCreateTaskAppointment,
    handleDisassociateAndAssociateTaskAppointment,
  }: AppointmentListProps) => {
    const styles = useStyles();
    const {nextAppointments, pastAppointments} = response;

    const {t} = useTranslation();

    const {userData, appointmentType} = useAuthentication();

    const {toggleModal, appointmentModal} = useRelatedProductsModal();

    const {ref: modalRef} = useInView();

    const containerRef = useRef<HTMLDivElement>(null); // Reference to the container

    const wholeAppointmentList = useMemo(() => {
      return [...nextAppointments, ...pastAppointments];
    }, [nextAppointments, pastAppointments]);

    const hasPastAppointmentsOnFirstThree = useMemo(() => {
      const hasPast = wholeAppointmentList
        .slice(0, 3)
        .findIndex(appointment =>
          dayjs(appointment.date).utc().isBefore(dayjs().utc()),
        );
      return hasPast !== -1;
    }, [wholeAppointmentList]);

    const hasPastAppointmentsOnFirstFour = useMemo(() => {
      const hasPast = wholeAppointmentList
        .slice(0, 4)
        .findIndex(appointment =>
          dayjs(appointment.date).utc().isBefore(dayjs().utc()),
        );
      return hasPast !== -1;
    }, [wholeAppointmentList]);

    const calculateHeight = useCallback((numElements: number) => {
      const container = containerRef.current;
      if (!container) {
        return {totalHeight: 0, totalElements: 0};
      }

      let totalHeight = 0;
      let totalElements = 0;
      for (let i = 0; i < numElements; i++) {
        if (container.children[i]) {
          totalHeight += (container.children[i] as HTMLDivElement)
            ?.offsetHeight;
          totalElements++;
        }
      }

      return {totalHeight, totalElements};
    }, []);

    const [showMore, setShowMore] = useState<boolean>(false);

    const appointmentShown = useCallback(
      (isOpen?: boolean) => {
        const hasNextApp = nextAppointments?.length > 0;
        if (!isOpen) {
          if (hasPastAppointmentsOnFirstThree && hasNextApp) {
            return 4;
          }
          if (hasPastAppointmentsOnFirstThree && !hasNextApp) {
            return 5;
          }

          return 3;
        } else {
          if (
            !hasPastAppointmentsOnFirstThree &&
            !hasPastAppointmentsOnFirstFour
          ) {
            return 4;
          }

          if (
            (hasPastAppointmentsOnFirstThree && hasNextApp) ||
            (!hasPastAppointmentsOnFirstThree && hasPastAppointmentsOnFirstFour)
          ) {
            return 5;
          }

          return 6;
        }
      },
      [
        hasPastAppointmentsOnFirstFour,
        hasPastAppointmentsOnFirstThree,
        nextAppointments?.length,
      ],
    );

    useEffect(() => {
      if (
        !showMore &&
        containerRef.current &&
        wholeAppointmentList.length > 0
      ) {
        const numOfElements = appointmentShown(false);
        const {totalElements, totalHeight} = calculateHeight(numOfElements);
        const addGaps = 16 * (totalElements - 1);
        containerRef.current.style.height = `${totalHeight + addGaps}px`;
        containerRef.current.className = `${containerRef.current.classList} closed`;
      }
    }, [
      appointmentShown,
      calculateHeight,
      hasPastAppointmentsOnFirstThree,
      nextAppointments.length,
      showMore,
      wholeAppointmentList.length,
    ]);

    const dialogAction = useRef<'CREATE' | 'EDIT' | 'DELETE' | undefined>(
      undefined,
    );

    const [showBookingDialog, setShowBookingDialog] = useState(false);

    const showMoreBoxes = useCallback(() => {
      if (!showMore && containerRef.current) {
        const numOfElements = appointmentShown(true);
        const {totalElements, totalHeight} = calculateHeight(numOfElements);
        const addGaps = 16 * (totalElements - 1);
        containerRef.current.style.height = `${totalHeight + addGaps}px`;
        containerRef.current.className = `${containerRef.current.classList} open`;
        setShowMore(prev => !prev);
      }
    }, [appointmentShown, calculateHeight, showMore]);

    const recalculateHeight = useCallback(() => {
      const nOfElements = appointmentShown(showMore);
      const {totalElements, totalHeight} = calculateHeight(nOfElements);
      const addGaps = 16 * (totalElements - 1);
      containerRef.current!.style.height = `${totalHeight + addGaps}px`;
    }, [appointmentShown, calculateHeight, showMore]);

    useEffect(() => {
      window.addEventListener('resize', recalculateHeight);

      return () => {
        window.removeEventListener('resize', recalculateHeight);
      };
    }, [calculateHeight, hasPastAppointmentsOnFirstThree, recalculateHeight]);

    useEffect(() => {
      const listener = (event: MessageEvent<WidgetResponseV2>) => {
        if (
          event?.origin !== process.env.REACT_APP_BASE_URL ||
          !(typeof event?.data === 'object' && 'id' in event?.data)
        ) {
          return;
        }
        queryClient.invalidateQueries({
          queryKey: ['getAppointmentHistory'],
        });
        setTimeout(() => {
          setShowBookingDialog(() => false);
        }, 1000);
      };

      window.addEventListener('message', listener);
      return () => {
        window.removeEventListener('message', listener);
      };
    }, []);

    const openTabLite = useCallback(
      (type: 'CREATE' | 'EDIT' | 'DELETE', appointment?: AppointmentV2) => {
        dialogAction.current = type;
        let config = {
          ...tabLiteBasicConfiguration(
            userData?.currentStore!,
            customer!,
            userData?.language!,
          ),
        };

        if (type !== 'CREATE') {
          config.appointmentId = appointment?.id;
          if (type === 'EDIT') {
            config.updateAppointment = true;
          } else {
            config.cancelAppointment = true;
          }
        }

        setShowBookingDialog(true);
        setTimeout(() => {
          console.log('TabLite configuration: ', JSON.stringify(config));
          const tabLite = window.TabLt.tabWidget.new(config);
          tabLite.render();
        }, 100);
      },
      [customer, userData?.currentStore, userData?.language],
    );

    const disassociateAndAssociateTaskAppointment = useCallback(
      (appointment: AppointmentV2) => {
        handleDisassociateAndAssociateTaskAppointment!({
          storeId: taskHeaderData?.storeId!,
          taskId: taskId!,
          banner: taskHeaderData?.banner!,
          country: taskHeaderData?.country!,
          id: appointment?.id,
          date: dayjs(appointment?.date).utc().format(),
          type: appointment?.type,
          firstname: appointment?.firstname,
          lastname: appointment?.lastname,
          email: appointment?.email,
          storeCode: appointment?.storeCode,
          phone: appointment?.phone,
          channel: appointment?.channel,
        });
      },
      [
        handleDisassociateAndAssociateTaskAppointment,
        taskHeaderData?.banner,
        taskHeaderData?.country,
        taskHeaderData?.storeId,
        taskId,
      ],
    );

    const createTaskAppointment = useCallback(
      (appointment: AppointmentV2) => {
        handleCreateTaskAppointment!({
          storeId: taskHeaderData?.storeId!,
          taskId: taskId!,
          banner: taskHeaderData?.banner!,
          country: taskHeaderData?.country!,
          id: appointment?.id,
          date: dayjs(appointment?.date).utc().format(),
          type: appointment?.type,
          firstname: appointment?.firstname,
          lastname: appointment?.lastname,
          email: appointment?.email,
          storeCode: appointment?.storeCode,
          phone: appointment?.phone,
          channel: appointment?.channel,
        });
      },
      [
        handleCreateTaskAppointment,
        taskHeaderData?.banner,
        taskHeaderData?.country,
        taskHeaderData?.storeId,
        taskId,
      ],
    );

    const {toggleModal: ToggleLinkToTaskModal, linkToTaskModal} =
      useLinkToTaskModal({disassociateAndAssociateTaskAppointment});

    const linkAction = useCallback(
      (app: AppointmentV2) => {
        if (
          !!currentAppointment ||
          !!app?.taskCampaignName ||
          !!app?.assigneeFirstName ||
          !!app?.assigneeLastName
        ) {
          ToggleLinkToTaskModal(app, currentAppointment);
          return;
        }
        createTaskAppointment(app);
      },
      [ToggleLinkToTaskModal, createTaskAppointment, currentAppointment],
    );

    /**
     * Appointment action
     * Switch appointment action based on current user appointmentType enum
     * @param type
     * @param appointment
     */
    const appointmentAction = useCallback(
      (
        appType: UserAppointmentType,
        actionType: 'CREATE' | 'EDIT' | 'DELETE',
        appointment?: AppointmentV2,
      ) => {
        if (appType === UserAppointmentType.TAB_RONA) {
          window.open(
            process.env.REACT_APP_TAB_RONA_URL!,
            '_blank',
            'noopener noreferrer',
          );
          return;
        }

        openTabLite(actionType, appointment);
        return;
      },
      [openTabLite],
    );

    const appointmentTypesMatch = useMemo(() => {
      if (!feedbackAppointmentType) {
        return true;
      }

      return feedbackAppointmentType === appointmentType;
    }, [appointmentType, feedbackAppointmentType]);

    const appointmentTypeIsReadOnly = useMemo(() => {
      return appointmentType === UserAppointmentType.READ_ONLY;
    }, [appointmentType]);

    /**
     * Render create appointment CTA
     */
    const renderCreateAppointmentCta = useMemo(() => {
      if (
        readOnly ||
        !appointmentType ||
        appointmentTypeIsReadOnly ||
        !appointmentTypesMatch
      ) {
        return undefined;
      }
      return (
        <Button
          disabled={!taskIsEditable}
          variant="primary"
          {...(taskIsEditable && {
            onClick: () => appointmentAction(appointmentType, 'CREATE'),
          })}>
          {t('CustomerProfile.AppointmentHistory.book')}
        </Button>
      );
    }, [
      appointmentAction,
      appointmentType,
      appointmentTypeIsReadOnly,
      appointmentTypesMatch,
      readOnly,
      t,
      taskIsEditable,
    ]);

    const renderNextAppointments = useMemo(() => {
      if (nextAppointments?.length === 0) {
        return (
          <Stack
            direction="column"
            gap={1.6}
            alignItems="center"
            {...(pastAppointments.length > 0 && {
              paddingBottom: 1.6,
            })}>
            <BodyText>
              {t('CustomerProfile.AppointmentHistory.noAppointments')}
            </BodyText>
            {!!renderCreateAppointmentCta && renderCreateAppointmentCta}
          </Stack>
        );
      }

      return (
        <>
          {nextAppointments?.map((appointment, index) => {
            return (
              <AppointmentHistoryCard
                appointmentType={feedbackAppointmentType ?? appointmentType}
                appointmentAction={appointmentAction}
                cardType={'NEXT'}
                key={appointment?.id}
                {...appointment}
                taskIsEditable={taskIsEditable}
                readOnly={readOnly}
                taskId={taskId}
                currentStoreId={currentStoreId}
                toggleModal={toggleModal}
                linkAction={() => linkAction(appointment)}
              />
            );
          })}
        </>
      );
    }, [
      nextAppointments,
      pastAppointments.length,
      t,
      renderCreateAppointmentCta,
      feedbackAppointmentType,
      appointmentType,
      appointmentAction,
      taskIsEditable,
      readOnly,
      taskId,
      currentStoreId,
      toggleModal,
      linkAction,
    ]);

    const renderPagination = useMemo(() => {
      return (
        <Box margin="0 auto">
          <Button variant="baseSecondary" onClick={showMoreBoxes}>
            {t('CustomerProfile.AppointmentHistory.showMore')}
          </Button>
        </Box>
      );
    }, [showMoreBoxes, t]);

    const renderPastAppointments = useMemo(() => {
      return (
        <>
          <LineText>
            <XSText uppercase>
              {t('CustomerProfile.AppointmentHistory.pastAppointments')}
            </XSText>
          </LineText>
          {pastAppointments?.map((appointment, index) => {
            return (
              <Box key={appointment?.id}>
                <AppointmentHistoryCard
                  appointmentType={feedbackAppointmentType ?? appointmentType}
                  cardType={'PAST'}
                  {...appointment}
                  taskIsEditable={taskIsEditable}
                  readOnly={readOnly}
                  taskId={taskId}
                  currentStoreId={currentStoreId}
                  toggleModal={toggleModal}
                  linkAction={() => linkAction(appointment)}
                />
              </Box>
            );
          })}
        </>
      );
    }, [
      t,
      pastAppointments,
      feedbackAppointmentType,
      appointmentType,
      taskIsEditable,
      readOnly,
      taskId,
      currentStoreId,
      toggleModal,
      linkAction,
    ]);

    return (
      <>
        <Stack sx={styles.appointmentList}>
          <Stack
            sx={styles.appointmentList}
            marginTop={0.8}
            ref={containerRef}
            id={'cardContainer'}>
            {renderNextAppointments}
            {pastAppointments.length > 0 && renderPastAppointments}
          </Stack>
          {!showMore && wholeAppointmentList.length > 3 && renderPagination}
        </Stack>
        {showBookingDialog && (
          <Modal
            open={showBookingDialog}
            onClose={() => setShowBookingDialog(false)}
            dialogContent={
              <Box ref={modalRef} id="tabLite" sx={styles.tabLite} />
            }
          />
        )}
        {appointmentModal}
        {linkToTaskModal}
      </>
    );
  },
);

AppointmentList.displayName = 'AppointmentList';

interface AppointmentHistoryCardProps extends AppointmentV2 {
  appointmentType?: UserAppointmentType;
  taskIsEditable: boolean;
  readOnly: boolean;
  cardType: 'NEXT' | 'PAST';
  appointmentAction?: (
    appType: UserAppointmentType,
    actionType: 'CREATE' | 'EDIT' | 'DELETE',
    appointment?: AppointmentV2,
  ) => void;
  taskId?: string;
  currentStoreId?: string;
  toggleModal?: (products: AppointmentV2 | undefined) => void;
  linkAction?: () => void;
  assignType?: 'ASSIGN' | 'DELETE';
}

export const AppointmentHistoryCard = React.memo(
  ({
    appointmentType,
    taskIsEditable,
    readOnly,
    cardType = 'NEXT',
    appointmentAction = undefined,
    taskId = undefined,
    currentStoreId,
    toggleModal,
    linkAction,
    assignType,
    ...appointment
  }: AppointmentHistoryCardProps) => {
    const styles = useStyles();
    const {getTranslationWithValue} = useBaseTranslation(
      'FeedbackBox.APPOINTMENT_SETTING',
    );
    const {t} = useTranslation();

    const appointmentDate = useMemo(() => {
      if (!appointment?.date) {
        return '-';
      }

      const formattedDate = capitalizeWords(
        dayjs(appointment?.date)
          .utc()
          .format(cardType === 'NEXT' ? 'll - LT' : 'MM/YYYY'),
      );

      if (cardType === 'PAST') {
        return formattedDate;
      }

      return (
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          gap={0.8}>
          <BodyText className={'label appointmentDateLabel'} medium>
            {formattedDate}
          </BodyText>
          {!assignType && (
            <BodyText className={'scheduledLabel'} heavy>
              {t('CustomerProfile.AppointmentHistory.scheduled')}
            </BodyText>
          )}
        </Stack>
      );
    }, [appointment?.date, assignType, cardType, t]);

    const appointmentStore = useMemo(() => {
      if (!appointment?.storeName) {
        return '-';
      }

      return (
        <Stack
          width={'fit-content'}
          display={'grid'}
          gridTemplateColumns={'1fr max-content'}
          alignItems={'center'}
          gap={1}>
          <BodyText className="label" medium sx={{wordBreak: 'break-all'}}>
            {appointment?.storeName}
          </BodyText>
          <IconSVG icon={'shop'} size={16} />
        </Stack>
      );
    }, [appointment?.storeName]);

    const showRelatedProducts = useMemo(
      () => appointment?.products?.length > 0,
      [appointment?.products?.length],
    );

    return (
      <Stack
        sx={styles.card}
        className={`appointmentCard__${cardType} ${
          !!assignType ? `appointmentCard__${assignType}` : ''
        }`}>
        <Stack
          display={'grid'}
          gridTemplateColumns={'1fr max-content'}
          alignItems={'center'}>
          <BodyText heavy className={'cardTitle'}>
            {getTranslationWithValue(
              0,
              `card.appointmentTypes.${appointment?.type}`,
            )}
          </BodyText>
          {showRelatedProducts && !!toggleModal && (
            <Button
              variant={'outlinedInverted'}
              {...(showRelatedProducts && {
                onClick: () => toggleModal(appointment),
              })}>
              {t('CustomerProfile.AppointmentHistory.showRelatedProducts')}
            </Button>
          )}
        </Stack>
        {cardType === 'NEXT' && (
          <>
            <LabelValue
              label={getTranslationWithValue(0, 'card.appointmentDate')}
              value={appointmentDate}
            />
            <LabelValue
              label={getTranslationWithValue(0, 'card.appointmentLocation')}
              value={appointmentStore}
            />
            <LabelValue
              label={getTranslationWithValue(0, 'card.contacts')}
              value={
                <BodyText
                  className="label"
                  medium
                  sx={{wordBreak: 'break-word'}}>
                  {appointment?.email ?? '-'} | {appointment?.phone ?? '-'}
                </BodyText>
              }
            />
          </>
        )}
        {cardType === 'PAST' && (
          <Stack
            display={'grid'}
            gridTemplateColumns={{xs: '1fr', lg: 'repeat(2, 1fr)'}}
            alignItems={'center'}
            gap={{xs: 1.6, lg: 0.8}}>
            <LabelValue
              label={getTranslationWithValue(0, 'card.appointmentDate')}
              value={appointmentDate}
            />
            <LabelValue
              label={getTranslationWithValue(0, 'card.contacts')}
              value={
                <BodyText
                  className="label"
                  medium
                  sx={{wordBreak: 'break-word'}}>
                  {appointment?.email ?? '-'}
                </BodyText>
              }
            />
          </Stack>
        )}
        {currentStoreId === appointment?.storeCode && (
          <Stack
            direction={'row'}
            alignItems={'center'}
            gap={1.6}
            display={
              !(appointment?.channel === appointmentType && !!taskId) &&
              !(!readOnly && cardType === 'NEXT')
                ? 'contents'
                : 'flex'
            }>
            {appointment?.channel === appointmentType && !!taskId && (
              <Button
                disabled={!taskIsEditable}
                variant="outlinedInverted"
                endIcon={<IconSVG icon="attach" size={16} />}
                onClick={linkAction}>
                {getTranslationWithValue(0, 'card.linkToTask')}
              </Button>
            )}
            {!readOnly && cardType === 'NEXT' && (
              <Button
                disabled={!taskIsEditable}
                variant="outlinedInverted"
                endIcon={<IconSVG icon="edit" size={16} />}
                {...(appointmentAction &&
                  taskIsEditable && {
                    onClick: () =>
                      appointmentAction(
                        appointment?.channel as UserAppointmentType,
                        'EDIT',
                        appointment,
                      ),
                  })}>
                {getTranslationWithValue(0, 'card.edit')}
              </Button>
            )}
          </Stack>
        )}
      </Stack>
    );
  },
);

AppointmentHistoryCard.displayName = 'AppointmentHistoryCard';
